import React from "react"
import notFoundImage from "../images/not-found-image.svg"

const NotFound = () => {
  return (

    <main className="flex flex-col justify-around flex-grow max-w-4xl mx-auto">
      <img className="p-4" src={notFoundImage} alt="Page not found" />
    </main>

  )
}

export default NotFound